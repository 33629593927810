import React from "react";
const bg = require("./office.jpg");
const logo = require("./logo-black.png");

function App() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      name,
      email,
      message,
      phoneNumber,
    });

    setName("");
    setEmail("");
    setMessage("");
    setPhoneNumber("");
  };

  return (
    <>
      <head>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
        </style>
      </head>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          margin: -8,
          fontFamily: "Poppins",
          color: "#303030",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            flexDirection: "row",
            gap: 36,
          }}
        >
          <div
            style={{
              width: 440,
              height: 300,
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              backdropFilter: "blur(8px)",
              WebkitBackdropFilter: "blur(8px)",
              borderRadius: "8px",
              border: "1px solid rgba(255, 255, 255, 0.18)",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              userSelect: "none",
            }}
          >
            <img src={logo} style={{ width: 120 }} />
            <div style={{ textAlign: "center" }}>
              <h1 style={{ marginBottom: -24 }}>Flowin.tech</h1>
              <h4 style={{ fontWeight: '500'}}>Consultoria de softwares customizados</h4>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            style={{
              width: 440,
              height: 360,
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              backdropFilter: "blur(8px)",
              WebkitBackdropFilter: "blur(8px)",
              borderRadius: "8px",
              border: "1px solid rgba(255, 255, 255, 0.18)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <h4>Solicite seu orçamento</h4>
            <p style={{ fontSize: 12 }}>
              Preencha o formulário abaixo para solicitar um orçamento.
            </p>
            <input
              type="text"
              id="name"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ fontFamily: "Poppins" }}
            />
            <input
              type="email"
              id="email"
              placeholder="Email"
              style={{ fontFamily: "Poppins" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="tel"
              id="tel"
              style={{ fontFamily: "Poppins" }}
              placeholder="Telefone"
              value={email}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <textarea
              id="message"
              placeholder="Descreva sua ideia"
              style={{ fontFamily: "Poppins" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit" style={{ fontFamily: "Poppins" }}>
              Solicitar orçamento
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default App;
